import { createApp } from 'vue';
import { q }         from '@lib/dom';
import { trace }     from '@/mixins/base/log';

export default async function(params) {
  const root = q('#agreements-app', 'one');

  trace('agreements-app entrypoint loaded');
  if(root) {
    trace('agreements-app entrypoint / condition is satisfied');
    const mixinBase = await import('@/mixins/base.js');
    const mixinAuth = await import('@/mixins/auth.js');

    let elLayout = q('.b-layout__global', 'one');

    const App = await import('@cmp/AgreementsApp/index.vue');
    const app = createApp(App.default, { root });

    app.mixin(mixinBase.default);
    app.mixin(mixinAuth.default);

    app.mount('#' + root.id);
  }

}
