import { createApp } from 'vue';
import { q } from '@lib/dom';
import { trace }     from '@/mixins/base/log';

export default async function(params) {
  const appName = 'profile-info-app';
  const root = q('#profile-info-app', 'one');

  trace(`${appName} entrypoint loaded`);
  if(root) {
    trace(`${appName} entrypoint / condition is satisfied`);
    const mixinBase = await import('@/mixins/base.js');
    const App = await import('@cmp/PartnerInfo/index.vue');
    const app = createApp(App.default, { root });

    app.mixin(mixinBase.default);

    app.mount('#' + root.id);
  }

}
