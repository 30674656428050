import { isObject, isArray } from './type.js';
import { arrayToCamelCase, apToArrayValue, apToArrayKeys } from './array.js';
import { capitalize } from './string.js';

export function checkKey(object, path, delimiter = '.') {
  const arrPath = path.split(delimiter);
  arrPath.splice(0, 1);
  let tmp = object;
  for (let i = 0; i < arrPath.length; i++) {
    const item = arrPath[i];
    tmp = tmp[item];
    if (tmp === undefined) return false;
  }
  return tmp;
}

export const setKey = (object, path, value, delimiter = '.', travelIndex = 0) => {
  const arrPath = path.split(delimiter);
  const key = arrPath[travelIndex];
  let isEnd = travelIndex >= arrPath.length - 1;
  let node = object[key] || {};
  if (isEnd) return { ...node, [key]: value };
  else return { ...object, [key]: { ...node, ...setKey(node, path, value, delimiter, ++travelIndex) } };
}
export const guardObject = (key, obj, def = false) => {
  const keys = Object.keys(obj);
  if (!keys.includes(key)) {
    if (def === false) return obj[keys[0]];
    return obj[def];
  }
  return obj[key];
};

export const guardObjectKey = (key, obj, def = false) => {
  const keys = Object.keys(obj);
  if (!keys.includes(key)) {
    if (def === false) return keys[0];
    return def;
  }
  return key;
};
export const only = props => object => {
  let res = {};
  props.forEach(prop => { res[prop] = object[prop] || null; });
  return res;
};

export const fabric = (keys = [], vals = []) => keys.reduce((acc, cur, i) => {
  acc[cur] = vals[i] === undefined ? null : vals[i];
  return acc;
}, {});

export const addProperty = (key, value, obj) => ({ [key]: value, ...obj });
// eslint-disable-next-line
export const removeProperty = prop => ({ [prop]: undefined, ...object }) => object;
// removeProperty('propname')(object);
export const renameProperty = (oldProp, newProp, obj) => removeProperty(oldProp)(addProperty(newProp, obj[oldProp], obj));

export const without = props => object => {
  let res = { ...object };
  props.forEach(prop => (res = removeProperty(prop)({ ...res })));
  return res;
};

export const filterProperties = (object, props) => props.reduce((acc, crr) => { acc[crr] = object[crr]; return acc; }, {});

export const clearPropsByValue = (_obj, value = null) => {
  let obj = { ..._obj };
  let keys = Object
    .entries(obj)
    .map(item => item[1] === value ? item[0] : false)
    .filter(item => item || false);
  keys.forEach(item => { obj = removeProperty(item)(obj); });
  return obj;
};

export const setProperty = (object, key, val) => ({ ...object, [key]: val });

export const objectToCamelCase = o => Object.entries(o)
  .map(([key, value]) => {
    const re = /(\-[a-z]{1})/gim;
    let nKey = undefined;
    if (re.test(key)) nKey = key.replace(re, capitalize);
    return [key, nKey, value];
  })
  .reduce((sum, [key, nKey, _value]) => {
    let value = null;
    if (isObject(_value)) value = objectToCamelCase(_value);
    else if (isArray(_value)) value = _value.map(itm => isObject(itm) ? objectToCamelCase(itm) : isArray(itm) ? arrayToCamelCase(itm) : itm);
    else value = _value;
    let res = { ...sum, [key]: value };
    if (nKey) res[nKey] = value;
    return res;
  }, {});

export const apToObjectValue = (o, fn) => {
  const process = val => {
    if (isObject(val)) return apToObjectValue(val, fn);
    else if (isArray(val)) return apToArrayValue(val, fn);
    else return fn(val);
  };
  return Object.entries(o)
    .map(([key, value]) => [key, process(value)])
    .reduce((s, [key, value]) => (s[key] = value, s), {});
};

export const apToObjectKeys = (o, fn) => {
  const process = (key, val) => {
    let value = val;
    if (isObject(val)) return { key: fn(key), value: apToObjectKeys(val, fn) };
    else if (isArray(val)) return { key: fn(key), value: apToArrayKeys(val, fn) };
    else return { key: fn(key), value };
  };
  return Object.entries(o)
    .map(([key, value]) => {
      const { key: _key, value: _value } = process(key, value)
      return [_key, _value];
    })
    .reduce((s, [key, value]) => (s[key] = value, s), {});
};


export default {
  addProperty,
  apToObjectKeys,
  apToObjectValue,
  checkKey,
  clearPropsByValue,
  fabric,
  filterProperties,
  guardObject,
  guardObjectKey,
  objectToCamelCase,
  only,
  removeProperty,
  renameProperty,
  setKey,
  setProperty,
  without,
};