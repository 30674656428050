import { createApp } from 'vue';
import { q, insert, element } from '@lib/dom';
import { len } from '@lib/array';
import { trace } from '@/mixins/base/log';

export default async function(params) {
  const collection = [...q('.b-table-app', 'all') || [], ...q('.b-content-page__article-content table', 'all')];

  trace('table-app entrypoint loaded');
  if (collection && len(collection)) {
    trace('table-app entrypoint / condition is satisfied');
    const mixinBase = await import('@/mixins/base.js');
    const mixinAuth = await import('@/mixins/auth.js');
    const App = await import('@cmp/Table/index.vue');
    const { Table: TH = {} } = await import('@cmp/Table/Table.js');
    const { BEM } = await import('@lib/css.js');
    const {
      isString,
      isFunction,
    } = await import('@lib/type.js');

    const __bem = new BEM({ block: 'b-table', dlModificator: '--' });
    const $bem = param => {
      if (isString(param)) {
        return pipe(
          str => str.trim(),
          str => str.split(/\s/),
          arr => [...new Set(arr)],
          arr => arr.join(' '),
        )(param)
      } else if (isFunction(param)) {
        const fn = param;
        return fn(__bem) + '';
      }
    };
    const getData = elTable => {
      const trItems = q('tr', 'all', elTable);
      const [headerRow, ...bodyRows] = trItems;
      const headerCells = [...q('td', 'all', headerRow)]
        .map(cell => TH.makeCell.bind(TH, cell.innerText, { isTitle: true }))
      const table = new TH({ header: TH.makeRow(...headerCells)(), props: { class: $bem(b => b.b('b-table')) } });
      [...bodyRows]
        .forEach(row => {
          const cellItems = q('td', 'all', row);
          const rowCells = [...cellItems]
            .map(cell => TH.makeCell.bind(TH, cell.innerText, {}));
          table.addBodyRow(_ => TH.makeRow(...rowCells)({}));
        });
      table.setupBody();
      return table;
    };

    [...collection].forEach(item => {
      let elTable = null;
      let elWrapper = null;
      if (item.nodeName === 'TABLE') {
        elWrapper = element('div', { class: 'b-table-app' });
        elTable = item;
        elTable.insertAdjacentElement('beforebegin', elWrapper);
        insert(elTable, elWrapper);
      } else {
        elWrapper = item;
        elTable = q('table', 'one', elWrapper);
      }
      if (!elTable) return null;
      const table = getData(elTable);
      const app = createApp(App.default, { table });
      app.mixin(mixinBase.default);
      app.mixin(mixinAuth.default);
      app.mount(elWrapper);
    });

  }

}