export const rndMinMax = (min, max) => Math.random() * (max - min) + min;
export const rndMinMaxInt = (min, max) => Math.floor(Math.random() * (max - min + 1)) + min;
export const rndCoinInt = () => ~~Math.floor(Math.random() * 2);
export const rndCoinBool = () => (~~Math.floor(Math.random() * 2) === 0);
export const rndCoinFlip = (a, b) => (~~Math.floor(Math.random() * 2) === 0) ? a : b;
export const getRndIntLimited = (exludeArray, min, max) => {
  let req = () => {
    let rNewVal = rndMinMaxInt(parseInt(min), parseInt(max));
    if (exludeArray.indexOf(rNewVal) !== -1) return req();
    return rNewVal;
  };
  let newVal = req();
  return newVal;
};
export const rndFromArray = arr => arr[rndMinMaxInt(0, arr.length - 1)];
export const uniqArrayInt = (length, min, max) => {
  var unq = [];
  var rnd = 0;
  var flag = false;
  if (max - min < length) {
    length = max - min;
  }
  while (unq.length < length) {
    rnd = rndMinMaxInt(min, max);
    flag = false;
    for (var i = 0; i < unq.length; i++) {
      if (unq[i] === rnd) { flag = true; break; }
    }
    if (!flag) {
      unq.push(rnd);
    }
  }// while
  return unq;
};
export const range = (...args) => {
  let start = 0; let end = 0; let step = 0;
  if (args.length === 0) {
    return [];
  } if (args.length === 1) {
    start = 0; end = args[0]; step = 1;
  } else if (args.length === 2) {
    start = args[0]; end = args[1]; step = 1;
  } else if (args.length === 3) {
    start = args[0]; end = args[1]; step = args[2];
  } else {
    start = args[0]; end = args[1]; step = args[2];
  }
  const result = [];
  let isRun = true;
  let current = start;
  while (isRun) {
    result.push(current);
    current += step;
    if (current > end) {
      isRun = false;
      break;
    }
  }
  return result;
};
export const getPersentOfValues = (intValue, intMax) => {
  if (parseInt(intValue) === 0 || parseInt(intMax) === 0) return 0;
  return 100 / (parseInt(intMax) / parseInt(intValue));
};
export const getPersentOfMax = (intValue, intMax) => {
  if (parseInt(intValue) === 0 || parseInt(intMax) === 0) return 0;
  return (parseInt(intValue) * 100) / parseInt(intMax);
};
export const getPersent = (value, persent) => (value / 100) * persent;

export const getMiddle = arr => {
  let sum = arr.reduce((sum, current) => {
    // eslint-disable-next-line
    return sum += current;
  }, 0);
  return sum / arr.length;
};
export const getMiddleArray = (arr, limit) => {
  if (!limit && isNaN(parseInt(limit)) && parseInt(limit) === 0) {
    limit = 1;
  }
  if (arr.length === 0) {
    return 0;
  }
  let count = 0;
  let newArr = [...arr];
  let flag = true;
  let tmpArr = [];
  while (flag) {
    if (newArr[count + 1] !== undefined) {
      tmpArr.push(getMiddle([newArr[count], newArr[count + 1]]));
    }
    if (count > newArr.length) {
      count = -1;
      newArr = [...tmpArr];
      tmpArr = [];
    }
    if (newArr.length <= limit) {
      flag = false;
    }
    count++;
  }
  return newArr;
};
export const getDivideArray = _int => {
  if (_int === undefined) return false;
  if (_int === null) return false;
  if (_int === '') return false;
  if (typeof _int === 'object') return false;
  let int = parseInt(_int, 10);
  if (isNaN(int)) return false;
  if (int === undefined) return false;
  let res = [];
  for (let i = 0; i <= int; i++) {
    if (int % i === 0 && i !== 0) {
      res.push(i);
    }
  }
  return res;
};
export const multiplyComposition = _int => {
  if (_int === undefined) return false;
  if (_int === null) return false;
  if (_int === '') return false;
  if (typeof _int === 'object') return false;
  let int = parseInt(_int, 10);
  if (isNaN(int)) return false;
  if (int === undefined) return false;

  let arrDivide = getDivideArray(int);
  let isRun = true;
  let ret = [];
  let counter = 0;
  while (isRun) {
    if (counter > arrDivide.length) {
      isRun = false; break;
    }
    if (arrDivide[counter] === 1 || arrDivide[counter] === _int) {
      counter++;
      continue;
    }

    if (int % arrDivide[counter] === 0) {
      int = int / arrDivide[counter];
      ret.push(arrDivide[counter]);
      continue;
    }
    counter++;
  }
  return ret;
};
export const add = (...arr) => {
  function maxSqArray (arr) {
    let maxLen = 0;
    for (let i = 0; i < arr.length; i++) {
      let len = [...String(arr[i]).split('')];
      if (len.length > maxLen) maxLen = len.length;
    }
    return maxLen;
  }
  function createSqArray (arr) {
    let newArr = [];
    for (let i = 0; i < arr.length; i++) {
      let newArrEl = [...String(arr[i]).split('')];
      newArr.push(newArrEl);
    }
    return newArr;
  }
  function normalizeSqArray (arr, max) {
    for (let i = 0; i < arr.length; i++) {
      if (arr[i].length < max) {
        let count = max - arr[i].length;
        for (let c = 0; c < count; c++) {
          arr[i].unshift('0');
        }
      }
    }
    return arr;
  }
  function calcColumn (currentNumber, sumObject) {
    let sum = parseInt(currentNumber) + parseInt(sumObject.sum) + parseInt(sumObject.mem);
    let strArrSum = String(sum).split('');
    sumObject.sum = parseInt(strArrSum.splice(-1, 1));
    let memStr = strArrSum.length !== 0 ? strArrSum.join('') : '0';
    sumObject.mem = isNaN(parseInt(memStr)) ? 0 : parseInt(memStr);
    return sumObject;
  }

  let newArr = createSqArray(arr);
  let digitMaxLength = maxSqArray(arr);
  newArr = normalizeSqArray(newArr, digitMaxLength);
  let resArr = [];
  let mem = 0;
  for (let x = newArr[0].length - 1; x >= 0; x--) {
    let sumObject = {
      sum: 0,
      mem: mem === 0 ? 0 : mem
    };
    for (let y = 0; y < newArr.length; y++) {
      sumObject = calcColumn(newArr[y][x], sumObject);
    }
    mem = sumObject.mem;
    resArr.unshift(sumObject.sum);
  }
  if (mem !== 0) {
    resArr.unshift(mem);
  }
  return String(resArr.join(''));
};

export const getRatio = (a, A, b) => A * b / a;

export default {
  rndMinMax,
  rndMinMaxInt,
  rndCoinInt,
  rndFromArray,
  rndCoinBool,
  rndCoinFlip,
  getRndIntLimited,
  uniqArrayInt,
  range,
  getPersentOfValues,
  getPersentOfMax,
  getPersent,
  getMiddle,
  getMiddleArray,
  getDivideArray,
  multiplyComposition,
  add,
  getRatio
};
