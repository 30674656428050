import { Version } from '@/helpers/Version';
import { version, name as frontendName } from '@root/package.json';
import { version as versionVue3FormGenerator, name as generatorName } from '@/plugins/vue3-form-generator/package.json';
import { version as versionLibrary, name as libraryName } from '@/library/package.json';

export const namespace = 'infotecs';
export const siteName = `infotecs.ru / ${globalThis.location.hostname}`;

// TODO: проверить в разных окружениях.
export const getMode = _ => import.meta.env.MODE;

export const isDev = _ => getMode() === 'development';
export const isProd = _ => getMode() === 'production' || getMode() === '';

const isStand = _ => /k8s/gim.test(window.location.hostname);
const isLocal = _ => /local/gim.test(window.location.hostname);

export const isDevCond = (isDev() || isStand() || isLocal());

export const logLevels = ['n', 'v', 'w', 'f',];
const logLevelSelector = isProd() ? 'n' : 'v';

export const logLevel = logLevels.find(itm => itm === logLevelSelector);

const _enableDebug = _ => sessionStorage.setItem(`debug-${namespace}`, true);
const enableDebug = _ => _enableDebug();

const _disableDebug = _ => sessionStorage.removeItem(`debug-${namespace}`);
const disableDebug = _ => _disableDebug();

const _getDebugStatus = _ => sessionStorage.getItem(`debug-${namespace}`) === 'true';
export const getDebugStatus = _ => _getDebugStatus();

const _enableTrace = _ => sessionStorage.setItem(`trace-${namespace}`, true);
const enableTrace = _ => _enableTrace();

const _disableTrace = _ => sessionStorage.removeItem(`trace-${namespace}`);
const disableTrace = _ => _disableTrace();

const _getTraceStatus = _ => sessionStorage.getItem(`trace-${namespace}`) === 'true';
export const getTraceStatus = _ => _getTraceStatus();

const _clearStore = _ => {
  const clean = _ => Object.keys(localStorage).map(k => k.indexOf('_ym') !== -1 ? (localStorage.removeItem(k), true) : false);
  const loop = _ => {
    clean();
    setTimeout(loop, 100);
  }
  loop();
};

const clearStore = _ => _clearStore();

const _enableDev = _ => sessionStorage.setItem(`dev-${namespace}`, true);
const enableDev = _ => _enableDev();

const _disableDev = _ => sessionStorage.removeItem(`dev-${namespace}`);
const disableDev = _ => _disableDev();

const _getDevStatus = _ => sessionStorage.getItem(`dev-${namespace}`) === 'true';
export const getDevStatus = _ => _getDevStatus();

const _showVersion = _ => {
  const mode = getMode();
  new Version({ mode })
    .push({ version, key: isDev() ? frontendName : 'version' }, true)
    .push({ mode })
    .push({ versionVue3FormGenerator, key: generatorName })
    .push({ versionLibrary, key: libraryName })
    .push({ siteName, key: 'project' })
    .show();
};
export const showVersion = _ => _showVersion();

export const methods = {

  // debug
  ...(_ => {
    if (isDev()) return {
      enableDebug,
      disableDebug,
      getDebugStatus,
    };
    return {};
  })(),

  // trace
  ...(_ => {
    if (isDev()) return {
      enableTrace,
      disableTrace,
      getTraceStatus,
    };
    return {};
  })(),

  // dev
  ...(_ => {
    if (isDev()) return {
      enableDev,
      disableDev,
      getDevStatus,
    };
    return {};
  })(),

  // version
  showVersion,
};

export default {
  namespace,
  methods,
  logLevel,
}