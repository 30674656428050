// TODO: make tests
import { isArray, int, flt, str } from './type.js';
import { delay } from './async.js';
export const isNode = o => {
  return (
    typeof Node === 'object' ? o instanceof Node
      : o && typeof o === 'object' && typeof o.nodeType === 'number' && typeof o.nodeName === 'string'
  );
};

// Returns true if it is a DOM element
const isElement = o => {
  return (
    typeof HTMLElement === 'object'
      ? o instanceof HTMLElement
      : o &&
        typeof o === 'object' &&
        o !== null &&
        o.nodeType === 1 &&
        typeof o.nodeName === 'string'
  );
};

export const insert = (child, _root = null) => {
  const root = _root || document.body;
  if (!child) return false;
  root.appendChild(child);
};
export const eject = node => {
  if (!node) return false;
  const parent = node?.parentNode;
  if(!parent) return null;
  parent.removeChild(node);
};

export const element = (type, params = {}) => {
  const {
    id,
    class: cls = null,
    style,
    text,
    title,
    event,
    href,
    target,
    src,
    alt,
    async,
    inputType = 'text',
    scriptType = 'text/javascript'
  } = params;

  const newElement = document.createElement(type);
  if (id) newElement.setAttribute('id', id);
  if (cls) newElement.setAttribute('class', cls);
  if (style) newElement.setAttribute('style', style);
  if (text) newElement.innerText = text;
  if (title) newElement.title = title;
  if (event) newElement.addEventListener(event.type, () => { event.handler({ element: newElement }); });
  if (type === 'a' && href) newElement.setAttribute('href', href);
  if (type === 'a' && target) newElement.setAttribute('target', target);
  if (type === 'img' && src) newElement.setAttribute('src', src);
  if (type === 'img' && alt) newElement.setAttribute('src', alt);
  if (type === 'script' && src) newElement.setAttribute('src', src);
  if (type === 'script' && async) newElement.setAttribute('async', async);
  if (type === 'script' && scriptType) newElement.setAttribute('type', scriptType);
  if (type === 'input' && inputType) newElement.setAttribute('type', inputType);
  return newElement;
};

export const strip = html => {
  let doc = new DOMParser().parseFromString(html, 'text/html');
  return doc.body.textContent || "";
};

export const getParent = (element, attribute, val = -1 ) => {
  let parent = element.parentNode;
  if(!parent) return null;
  while(parent !== null) {
    if(/document/.test(parent.nodeName)) {
      return parent;
    } else if(attribute === 'class') {
      if(val!== -1 && parent.classList.contains(val)) return parent;
      else if(val === -1 && hasAttr(parent, 'class')) return parent;
    } else if(attribute === 'id') {
      if(parent.id === val) return parent;
      else if(val === -1 && hasAttr(parent, 'id')) return parent;
    } else if(attribute === 'tag') {
      if(parent.tagName.toLocaleUpperCase() === val.toLocaleUpperCase()) return parent;
    } else {
      if(hasAttr(parent, attribute) && getAttr(parent, attribute) === val) return parent;
      else if(val === -1 && hasAttr(parent, attribute) && getAttr(parent, attribute)) return parent;
    }
    parent = parent.parentNode;
  }
  return null;
}

export const clss = ({ element, has, add, remove }) => {
  const cnts = (e, c) => e.classList.contains(c);
  const dd = (e, c) => e.classList.add(c);
  const rem = (e, c) => e.classList.remove(c);
  if (!element && !isNode(element)) return false;
  if (has) return cnts(element, has);
  if (add && !cnts(element, add)) dd(element, add);
  if (remove && cnts(element, remove)) rem(element, remove);
};

export const getCoords = el => {
  if (!isNode(el)) return {};
  const { x, y, left, right, top, bottom, width, height } = el.getBoundingClientRect();
  const {
    borderWidth,
    borderTopWidth,
    borderRightWidth,
    borderLeftWidth,
    borderBottomWidth,
    padding,
    paddingTop,
    paddingLeft,
    paddingRight,
    paddingBottom,
    margin,
    marginTop,
    marginLeft,
    marginRight,
    marginBottom,
    gap,
    gridColumnGap,
    gridRowGap,
  } = getComputedStyle(el);

  let extra = {
    borderWidth,
    borderTopWidth,
    borderRightWidth,
    borderLeftWidth,
    borderBottomWidth,
    padding,
    paddingTop,
    paddingLeft,
    paddingRight,
    paddingBottom,
    margin,
    marginTop,
    marginLeft,
    marginRight,
    marginBottom,
    gap,
    gridColumnGap,
    gridRowGap,
  };
  return { x, y, left, right, top, bottom, width, height, extra };
};

export const getAttr = (el, keyAttribute) => {
  if (!isNode(el)) return false;
  return el.getAttribute(keyAttribute);
};

export const hasAttr = (el, keyAttribute) => {
  if (!isNode(el)) return null;
  return el.hasAttribute(keyAttribute);
};

export const addAttr = (el, keyAttribute, valAttribute) => {
  if (!isNode(el)) return false;
  el.setAttribute(keyAttribute, valAttribute);
};

export const setAttr = addAttr;

export const delAttr = (el, keyAttribute) => {
  if (!isNode(el)) return false;
  el.removeAttribute(keyAttribute);
};

export const remAttr = delAttr;

export const domReady = (fn, after = null) => {
  let isRun = false;

  const clean = fn => document.removeEventListener('DOMContentLoaded', fn);

  const handler = _ => {
    if (!isRun) {
      isRun = true;
      fn && fn();
      clean(handler);
    }
  };

  document.addEventListener('DOMContentLoaded', handler);

  if (!isRun && (document.readyState === 'interactive' || document.readyState === 'complete')) {
    isRun = true;
    fn && fn();
    clean(handler);
  }
  after && after();
};

export const q = (selector, type = 'one', parent = document) => {
  if (!selector) return null;
  if (!isNode(parent)) return null;
  if (type === 'one') return parent.querySelector(selector);
  else if (type === 'all') return parent.querySelectorAll(selector);
  else if (type === 'id') return document.getElementById(selector);
  else if (type === 'name') return document.getElementsByName(selector);
  else if (type === 'tag') return document.getElementsByTagName(selector);
  else if (type === 'class') return parent.getElementsByClassName(selector);
  return null;
};

export const classSwitcher = (el, classA, classB, callbackA, callbackB) => {
  if (clss({ element: el, has: classA })) {
    clss({ element: el, add: classB, remove: classA });
    if (callbackA) callbackA();
  } else if (clss({ element: el, has: classB })) {
    clss({ element: el, add: classA, remove: classB });
    if (callbackB) callbackB();
  } else {
    clss({ element: el, add: classB });
  }
};

export const script = url => {
  if (isArray(url)) return Promise.all(url.map((item) => script(item)));
  return new Promise((resolve, reject) => {
    let r = false;
    let s = element('script', {
      src: url,
      async: true
    });
    s.onload = s.onreadystatechange = function () {
      if (!r && (!this.readyState || this.readyState === 'complete')) {
        r = true;
        resolve(this);
      }
    };
    s.onerror = s.onabort = reject;
    insert(s, q('head'));
  });
};

export const cssDoubleKill = cssString => {
  const getStyleRule = str => {
    const [rule, value] = str.split(':').map(item => item.trim());
    return { rule, value };
  };

  const parseStyleObjectToString = o => Object
    .entries(o)
    .reduce((acc, [key, value]) => `${acc}${key}: ${value}; `, '')
    .trim();

  const styles = cssString.split(';')
    .filter(item => !!item.trim());

  const object = styles.reduce((acc, cur) => {
    const { rule, value } = getStyleRule(cur);
    return { ...acc, [rule]: value };
  }, {});

  return parseStyleObjectToString(object);
};

export const stl = (el, cssObj) => {
  let oldStyle = false;
  if (el.hasAttribute('style')) {
    oldStyle = getAttr(el, 'style');
    oldStyle += ' ';
  }
  let style = oldStyle || '';
  for (let val in cssObj) {
    if (cssObj.hasOwnProperty(val)) {
      style += str(val) + ': ' + str(cssObj[val]) + '; ';
    }
  }
  style = cssDoubleKill(style);
  setAttr(el, 'style', style);
};

export const cmpStyle = (el, prop) => getComputedStyle(el)[prop];

export const getOffset = elem => {
  function getOffsetRect (argelem) {
    let box = argelem.getBoundingClientRect();
    let body = document.body;
    let docElem = document.documentElement;
    let scrollTop = window.pageYOffset || docElem.scrollTop || body.scrollTop;
    let scrollLeft = window.pageXOffset || docElem.scrollLeft || body.scrollLeft;
    let clientTop = docElem.clientTop || body.clientTop || 0;
    let clientLeft = docElem.clientLeft || body.clientLeft || 0;
    let top = Math.round(box.top + scrollTop - clientTop);
    let left = Math.round(box.left + scrollLeft - clientLeft);
    return { top, left };
  }
  function getOffsetSum (argelem) {
    let top = 0;
    let left = 0;
    while (argelem) {
      top = top + int(argelem.offsetTop);
      left = left + int(argelem.offsetLeft);
      argelem = argelem.offsetParent;
    }
    return { top, left };
  }
  if (elem.getBoundingClientRect) return getOffsetRect(elem);
  else return getOffsetSum(elem);
};

export class WatcherProperty {
  #timer = null;
  #element = null;
  #delay = null;
  #property = null;
  #valueMin = null;
  #valueMax = null;
  #units = null;
  #callback = null;
  #currentValue = null;
  constructor (arg) {
    if (!arg) return false;
    if (!arg.element) return false;
    if (!arg.property) return false;
    if (arg.valueMax === undefined && arg.valueMin === undefined) return false;
    if (arg.valueMax !== undefined && arg.valueMin !== undefined) return false;

    this.#element = arg.element;
    this.#delay = arg.delay || 100;
    this.#property = arg.property;
    this.#valueMin = arg.valueMin || false;
    this.#valueMax = arg.valueMax || false;
    this.#units = arg.units === false ? 'px' : arg.units;
    this.#callback = arg.callback || function (arg) { return arg; };

    this.#currentValue = this.#getValue(this.#element, this.#property);
  }
  #getValue (el, key) {
    let value = cmpStyle(el, key);
    return flt(value) || 0;
  }

  #complite () {
    clearTimeout(this.#timer);
    return this.#callback();
  }

  #checkCondition () {
    const condition1 = this.#valueMax === false && this.#valueMin !== false;
    const condition2 = this.#valueMax !== false && this.#valueMin === false;
    const condition3 = this.#valueMax !== false && this.#valueMin !== false;
    if (condition1 && this.#currentValue <= this.#valueMin) return true;
    if (condition2 && this.#currentValue >= this.#valueMax) return true;
    if (condition3) return true;
    return false;
  }

  watch () {
    this.#currentValue = this.#getValue(this.#element, this.#property);
    if (this.#checkCondition()) return this.#complite();
    this.#timer = setTimeout(_ => this.watch(), this.#delay);
  }
}

export const transition = async ({
  element,
  init,
  exec,
  finish
}) => {
  let inProcess = false;
  const removeEvents = _ => {
    element.removeEventListener('transitionend', transitionEnd);
  };
  async function transitionEnd (event) {
    if (inProcess === true) return false;
    inProcess = true;
    event.preventDefault();
    event.stopPropagation();
    if (finish) await finish();
    removeEvents();
    inProcess = false;
  }
  window.addEventListener('resize', removeEvents);
  window.addEventListener('scroll', removeEvents);
  element.addEventListener('transitionend', transitionEnd);

  if (init) await init();
  if (exec) {
    await delay({
      async callback () {
        await exec();
      },
      ms: 10
    });
  }
};

export const px = val => `${val}px`;
export const em = val => `${val}em`;
export const rem = val => `${val}rem`;

const defaultModule = {
  WatcherProperty,
  addAttr,
  classSwitcher,
  clss,
  cmpStyle,
  cssDoubleKill,
  delAttr, 
  domReady,
  element,
  em,
  getAttr,
  getCoords,
  getOffset,
  getParent,
  hasAttr,
  insert,
  isElement,
  isNode,
  px,
  q,
  rem,
  remAttr,
  script,
  setAttr,
  stl,
  strip,
  transition,
};

export default defaultModule;
