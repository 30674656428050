import agreementsApp from '@/entries/agreements-app';
import coinApp from '@/entries/coin-app.js';
import conferencesApp from '@/entries/conferences-app.js';
import profileInfoApp from '@/entries/profile-info-app';
import refundEventsApp from '@/entries/refund-events-app';
import refundPrApp from '@/entries/refund-pr-app';
import shopApp from '@/entries/shop-app';
import shoprequestsApp from '@/entries/shoprequests-app';
import tableApp from '@/entries/table-app';
import eventCalendarApp from '@/entries/event-calendar-app';

export default {
  agreementsApp,
  coinApp,
  conferencesApp,
  profileInfoApp,
  refundEventsApp,
  refundPrApp,
  shopApp,
  shoprequestsApp,
  tableApp,
  eventCalendarApp
}