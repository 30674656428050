// Dependencies:axios
import axios from 'axios';
import { rndMinMaxInt, rndFromArray, range } from './math.js';
import { pipe } from './fn.js';
import { s, co } from './array.js';
import { isBool, isNumber } from './type.js';



export function propMaker(
  type = String,
  defaultValue = null,
  required = false,
  validator = _ => true
) {
  return {
    type, default: defaultValue, required, validator
  };
}

export const getRndId = (start = 3, end = 9, _library = null) => {
  const library = _library || 'qwertyuiopasdfghjklzxcvbnm_';
  const prefix = `${rndFromArray(library)}${rndFromArray(library)}`;
  const c = range(start, end)
    .map((item, i) => (i % start === 0 ? rndMinMaxInt(0, 9) : rndFromArray(library)))
    .join('');
  return start + prefix + c;
};
export const getRndIdAlt = (start = 3, end = 9, _library = null, _prefix = null, isRndAlphabethPrefix = false) => {
  let library = _library;
  let prefix = _prefix;
  let abc = 'qwertyuiopasdfghjklzxcvbnm'.split('');
  if (!library) {
    let syb = '_'.split('');
    let num = '0123456789'.split('');
    library = [].concat(abc, syb, num);
  }
  if (!prefix && isRndAlphabethPrefix) prefix = rndFromArray(abc);
  const count = rndMinMaxInt(start, end);
  let res = '';
  for (let index = 0; index < count; index++) res += rndFromArray(library);
  return prefix ? prefix + res : res;
};

export const watchValue = (n, o, fnArray) => s(n) !== s(o) ? pipe(...fnArray)() : n;

export const getRef = async function(key) {
  const self = this;
  const max = 1024;
  let counter = 0;
  let timer = null;
  let element = null;
  return new Promise((resolve, reject) => {
    const wait = _ => {
      element = self.$refs[key];
      if (element) {
        clearTimeout(timer);
        return resolve(element);
      }
      counter++;
      if (counter > max) {
        clearTimeout(timer);
        return reject();
      }
      timer = setTimeout(wait, 10);
    };
    wait();
  });
};

export const getRefFunction = self => getRef.bind(self);
//    beforeCreate () {
//      this.getRef = getRefFunction(this);
//    },

export const watchValueFunction = self => {
  return watchValue.bind(self);
  //    beforeCreate () {
  //      this.watchValue = watchValueFunction(this);
  //    },
};

export const Cache = {
  name: getRndId(3, 9),
  cache: new Map(),
  hash(args) { return args.map(item => s(item)).join(','); },
  hashSalt(salt = '', args = []) { return salt.concat(args.map(item => s(item)).join(',')); },
  getAll() { return Cache.cache; },
  remove(key) { Cache.cache.delete(key); },
  revert(func, hash, params = {}) {
    return async function() {
      const key = hash([func.name, ...arguments]);
      Cache.remove(key);
    };
  },
  reset() { Cache.cache.clear(); },
  /**
   * @param {Function} func  -- Вызываемая функция
   * @param {Function} hash  -- Функция формирования хеша
   * @param {Number}   expr  -- MS срок хранения хеша
   * @returns {any}    -- или вернет закешированное значение или выполнит функцию и закеширует результат.
   */
  __seconds(n) { return 1000 * n },
  __minutes(n) { return 1000 * 60 * n },
  __hours(n) { return 1000 * 60 * 60 * n },

  __cache(func, hash, params = {}) {
    const { expr = -1, isFlush = false } = params;
    return function() {
      const key = hash([func.name, ...arguments]);
      const cond1 = Cache.cache.has(key);
      const item = cond1 ? Cache.cache.get(key) : [undefined, {}];
      let val = item[0];
      let isExpired = false;
      let now = -1;
      let condHasExpr = typeof expr === 'number' && expr > 0;
      if (condHasExpr) {
        now = new Date().getTime();
        const { lastCall: lc = -1, ttl = -1 } = item[1];
        const condExpr = lc > 0 && ttl > 0;
        if (condExpr) isExpired = (now - lc) > ttl;
        else if (!condExpr && condHasExpr) isExpired = true;
      }
      if (!isExpired && !isFlush && val) return val;
      const result = func.call(this, ...arguments);
      Cache.cache.set(key, [result, { ttl: expr, lastCall: now }]);
      return result;
    };
  },
  __cacheAsync(func, hash, params = {}) {
    const { expr = -1, isFlush = false } = params;
    return async function() {
      const key = hash([func.name, ...arguments]);
      const cond1 = Cache.cache.has(key);
      const item = cond1 ? Cache.cache.get(key) : [undefined, {}];
      let val = item[0];
      let isExpired = false;
      let now = -1;
      let condHasExpr = typeof expr === 'number' && expr > 0;
      if (condHasExpr) {
        now = new Date().getTime();
        const { lastCall: lc = -1, ttl = -1 } = item[1];
        const condExpr = lc > 0 && ttl > 0;
        if (condExpr) isExpired = (now - lc) > ttl;
        else if (!condExpr && condHasExpr) isExpired = true;
      }
      if (!isExpired && !isFlush && val !== undefined) return val;
      const result = await func.call(this, ...arguments);
      Cache.cache.set(key, [result, { ttl: expr, lastCall: now }]);
      return result;
    };
  }
};

export class RequestBlobCache {
  /** Предназначен для загрузки и кеширования изображений. (но в теории не
   * только его)
   */
  cache = new Map();
  /*
   * {
   *   key: {key, value, blob, error}
   * }
   */
  constructor() { }

  static t(key, value, blob, error) { return { key, value, blob, error }; }
  static cleanCollection(...collection) {
    return collection.filter(({ error }) => !error);
  }
  static blobs(...collection) {
    return RequestBlobCache
      .cleanCollection(...collection)
      .map(({ blob }) => blob);
  }
  static values(...collection) {
    return RequestBlobCache
      .cleanCollection(...collection)
      .map(({ value }) => value);
  }

  setUpCacheItem(url, object) {
    const arr = Object.entries(object);
    let cacheItem = this.cache.get(url);
    arr.forEach(([key, val]) => cacheItem[key] = val);
    this.cache.set(url, cacheItem);
  }

  load(url) {
    const self = this;
    let isError = false;
    this.cache.set(url, RequestBlobCache.t(url, '', '', null));
    return new Promise(async (resolve) => {
      const reader = new (globalThis || window).FileReader();
      const res = await axios
        .get(url, { responseType: 'blob' })
        .catch(error => {
          isError = true;
          self.setUpCacheItem(url, { error });
        });
      if (isError) return resolve(self.cache.get(url));
      reader.readAsDataURL(res.data);
      reader.onload = function() {
        const blob = reader.result;
        const value = blob;
        self.setUpCacheItem(url, { value, blob });
        resolve(self.cache.get(url));
      }
    });
  }

  async get(url) {
    const { cache } = this;
    let res = '';
    if (cache.has(url)) res = cache.get(url);
    else res = await this.load(url);
    return res;
  }

  async blob(url) {
    const { blob = '' } = await this.get(url);
    return blob;
  }

  async value(url) {
    const { value = '' } = await this.get(url);
    return value;
  }

  async getCollection(...images) {
    const collection = images.map(url => this.get(url));
    const data = await Promise.all(collection);
    return data;
  }

};

export class Logger {
  #isEnabled = false;
  #excludeFnList = [];
  #excludeStrList = [];

  constructor({ isEnabled = false, excludeFnList = [], excludeStrList = [] }) {
    this.#isEnabled = isEnabled;
    this.#excludeFnList = excludeFnList;
    this.#excludeStrList = excludeStrList;
  }

  // access controll
  #accessControll(fn) {
    const flag = this.#isEnabled;
    const { name } = fn;
    const eName = name.substr(1, name.length - 2);
    const isAsseptFn = this.#excludeFnList.includes(eName);
    return (...args) => {
      const isAsseptStr = this.#excludeStrList
        .some(str => args
          .some(strArg => strArg === str)
        );
      if (flag && !isAsseptFn && !isAsseptStr) fn(...args);
    };
  }

  // log methods
  /* eslint-disable no-console*/
  #log(...args) { console.log(...args); }
  #info(...args) { console.info(...args); }
  #warn(...args) { console.warn(...args); }
  #warning(...args) { console.warn(...args); }
  #err(...args) { console.error(...args); }
  #error(...args) { console.error(...args); }
  #fatal(...args) { console.error(...args); }
  /* eslint-enable no-console*/

  log(...args) { this.#accessControll(this.#log)(...args); }
  info(...args) { this.#accessControll(this.#info)(...args); }
  warn(...args) { this.#accessControll(this.#warn)(...args); }
  warning(...args) { this.#accessControll(this.#warning)(...args); }
  err(...args) { this.#accessControll(this.#err)(...args); }
  error(...args) { this.#accessControll(this.#error)(...args); }
  fatal(...args) { this.#accessControll(this.#fatal)(...args); }

  // Statuses
  getStatus() { return this.#isEnabled; }

  enable() { this.#isEnabled = true; }
  disable() { this.#isEnabled = false; }

  excludeFn(str) { this.#excludeFnList.push(str); }
  excludeStr(str) { this.#excludeStrList.push(str); }
  flushExcludeFn() { this.#excludeFnList = []; }
  flushExcludeStr() { this.#excludeFnList = []; }
  popFromExcludeFn(str) {
    const index = this.#excludeFnList.findIndex(item => item === str);
    if (index !== -1) this.#excludeFnList.splice(index, 1);
  }
  popFromExcludeStr(str) {
    const index = this.#excludeStrList.findIndex(item => item === str);
    if (index !== -1) this.#excludeStrList.splice(index, 1);
  }
}

export class API {
  #urls = {};
  #methods = {};
  #headers = {};
  #action = {};
  #openTag = '%';
  #closeTag = '%';
  constructor({ headers = {}, urls = {} }) {
    this.#headers = { ...headers };
    this.#urls = { ...urls };
  }

  #r(str, key, val) {
    const ot = this.#openTag;
    const ct = this.#closeTag;
    const re = new RegExp(`${ot}${key}${ct}`);
    return str.replace(re, val);
  }
  #getArrReplacerForPipe(str, obj) {
    const r = this.#r.bind(this);
    return Object.entries(co(obj))
      .map(([key, val]) => str => r(str, key, val));
  }
  #rAll(str, obj) {
    const cls = this.#cls.bind(this);
    const getArrReplacerForPipe = this.#getArrReplacerForPipe.bind(this);
    let params = getArrReplacerForPipe(str, obj);
    params.push(...[cls, s => s]);
    return pipe(...params)(str);
  }

  #cls(str) {
    const ot = this.#openTag;
    const ct = this.#closeTag
    const re = new RegExp(`${ot}.*?${ct}`, 'igm');
    return str.replace(re, '');
  }

  #setOpenTag(str) {
    this.#openTag = str;
  }

  #setCloseTag(str) {
    this.#closeTag = str;
  }

  #resetTagsDefault(str) {
    this.#closeTag = '%';
    this.#openTag = '%';
  }

  #checkKey(key, object) { return Object.keys(object).includes(key); }

  async #get({
    url: _url = null,
    params = {},
    headers: _headers = {},
    body = {},
    urlTransform = val => val,
  }) {
    const url = this.#rAll(_url, params);
    const headers = { ...this.#headers, ..._headers };
    const res = await axios.get(urlTransform(url), { headers, params: body });
    return res;
  }

  async #post({
    url: _url = null,
    params = {},
    body = {},
    headers: _headers = {},
    followRedirect: _followRedirect = false,
    urlTransform = val => val,
  }) {
    const url = this.#rAll(_url, params);
    const headers = { ...this.#headers, ..._headers };
    let followRedirect = _followRedirect;
    if (isBool(followRedirect) && followRedirect === false) followRedirect = 0;
    if (isBool(followRedirect) && followRedirect === true) followRedirect = 1;
    else if (isNumber(followRedirect) === false) followRedirect = 0;
    else if (isNumber(followRedirect) && followRedirect < 0) followRedirect = 0;
    const res = await axios.post(urlTransform(url), body, {
      headers,
      maxRedirects: followRedirect
    });
    return res;
  }

  async method(methodName, params = {}) {
    const checkKey = this.#checkKey;

    if (!methodName) return false;
    if (!checkKey(methodName, this.#methods)) return false;
    if (this.#action[methodName] && this.#action[methodName].after) this.#action[methodName].before();
    const r = this.#r.bind(this);
    const cls = this.#cls.bind(this);
    const rAll = this.#rAll.bind(this);
    const replace = (str, key, val) => pipe(
      _ => r(_, key, val),
      _ => cls(_)
    )(str);
    const replaceAll = (str, obj) => rAll(str, obj);
    let res = await this.#methods[methodName](params, {
      replace: replace.bind(this),
      replaceAll: replaceAll.bind(this),
      url: this.getUrl(methodName),
      get: this.#get.bind(this),
      post: this.#post.bind(this)
    });
    if (this.#action[methodName] && this.#action[methodName].after) this.#action[methodName].after();
    return res;
  }

  addMethod(name, { url, method }, openTag = false, closeTag = false) {
    const setOpenTag = this.#setOpenTag.bind(this);
    const setCloseTag = this.#setCloseTag.bind(this);
    const resetTagsDefault = this.#resetTagsDefault.bind(this);
    if (!name) return false;
    if (url) this.#urls[name] = url;

    if (openTag || closeTag) {
      this.#action[name] = {
        before() {
          if (openTag) setOpenTag(openTag);
          if (closeTag) setCloseTag(closeTag);
        },
        after() {
          if (openTag || closeTag) resetTagsDefault();
        }
      }
    }

    this.#methods[name] = method;
    return this;
  }

  addUrl(object) {
    this.#urls = { ...this.#urls, ...object };
    return this;
  }

  addUrls(collection) {
    collection.forEach(item => {
      this.#urls = { ...this.#urls, ...item };
    });
    return this;
  }

  addHeader(object) {
    this.#headers = { ...this.#headers, ...object };
    return this;
  }

  addHeaders(collection) {
    collection.forEach(item => {
      this.#headers = { ...this.#headers, ...item };
    });
    return this;
  }

  getUrl(key) {
    const checkKey = this.#checkKey;
    if (!checkKey(key, this.#urls)) return false;
    return this.#urls[key];
  }

  getHeader(key) {
    const checkKey = this.#checkKey;
    if (!checkKey(key, this.#headers)) return false;
    return this.#headers[key];
  }

  getMethod(key) {
    const checkKey = this.#checkKey;
    if (!checkKey(key, this.#methods)) return false;
    return this.#methods[key];
  }

  setUrl(key, val) {
    return this.#urls[key] = val;
  }

  setHeader(key, val) {
    return this.#headers[key] = val;
  }


  log() {
    /* eslint-disable no-console*/
    console.log(this.#urls);
    console.log(this.#methods);
    console.log(this.#headers);
    /* eslint-enable no-console*/
    return this;
  }
}

export function middlewarePipline({ context, middleware, index }) {
  const nextMiddleware = middleware[index];
  if (!nextMiddleware) return context.next;
  return _ => {
    const nextPipeline = middlewarePipline({ context, middleware, index: ++index });
    nextMiddleware({ ...context, nextMiddleware, nextPipeline });
  };
}

export default {
  API,
  Cache,
  RequestBlobCache,
  Logger,
  getRef,
  getRefFunction,
  getRndId,
  getRndIdAlt,
  middlewarePipline,
  propMaker,
};