// description: Общие API методы для работы с сайтом
import { lens, prop, assoc } from 'ramda';
import { API, Cache } from '@lib/vue';
import { str } from '@lib/type';
import { getDebugStatus } from '@/settings';


export const headers = {
  'X-Requested-With': 'XMLHttpRequest',
  'X-Frontend-Request': str(new Date().valueOf()),
};
const api = new API({ headers });

export const headerKeyCsrf = 'X-Bitrix-Csrf-Token';
export const setCSRFToken= (val, obj) => {
  if(val) return {...obj, [headerKeyCsrf]: val};
  return obj;
};

export const debuggerTransform = str => {
  let url = new URL(str, location.origin);
  let params = url.searchParams;
  const isDebug = getDebugStatus();
  if(isDebug) params.append('debug', 108);
  return url.href;
};

api.addMethod('info', {
  url: `%pathname%`,
  method: async ({ csrf = null, noCache = false, isFlush = false }, { url, post }) => {
    const { pathname } = window.location;
    async function cachedInfo(csrf, pathname) {
      const body = { params: { action: 'info' } };
      const signedHeaders = setCSRFToken(csrf, headers);
      const res = await post({
        url, params: { pathname },
        urlTransform: debuggerTransform,
        body, headers: signedHeaders,
      });
      const { data } = res;
      return data;
    };
    const run = Cache.__cacheAsync(cachedInfo, Cache.hash, { isFlush } );
    return await (noCache ? cachedInfo : run)(csrf, pathname, url, 'POST');
  }
});

api.addMethod('logout', {
  url: `%pathname%?logout=yes&sessid=%csrf%&login=yes`,
  method: async ({ csrf = null }, { url, get }) => {
    const { pathname } = window.location;
    const signedHeaders = setCSRFToken(csrf, headers);
    const res = await post({
      url, params: { pathname, csrf },
      body, headers: signedHeaders,
      urlTransform: debuggerTransform,
    });
  }
});


export const exec = api.method.bind(api);

export default { exec, headers, setCSRFToken };
