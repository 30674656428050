// dependencies
import { createApp } from 'vue';
import { hasIn, lensProp, set, view, path } from 'ramda';

import { namespace } from '@/settings';
import { trace } from '@/mixins/base/log';

// library
import {
  clss,
  domReady,
  eject,
  element,
  insert,
  q,
} from '@lib/dom';
import { isString, isObject, isArray } from '@lib/type';
import { sp } from '@lib/stringTmpl';

export const createNotifyScene = (_id, _parent = null, _bemBlock = 'b-notify') => {
  trace('createNotifyScene()', { _id, _parent, _bemBlock });
  const id = _id || 'notify-scene';
  const bemBlock = _bemBlock || 'b-notify';
  const parent = _parent || document.body;
  let elNotifyScene = q(`#${id}`, 'one', parent);
  if (!elNotifyScene) {
    trace('createNotifyScene() notify scene is not exist. Will be created in next steps');
    elNotifyScene = element('div', { id, class: bemBlock });
    insert(elNotifyScene, parent);
  }
  return { element: elNotifyScene, id }
};

const { keys, entries } = Object;

// Kurwa!
const Scope = function Scope(_o = {}) {
  const o = _o;
  return function() { return o; }
}

class ModalManager {

  elBody = document.body;
  elHtml = document.body.parentElement;
  elNotifyScene = null; // сцена для монтирования компонентов
  elLayout = null; // слой куда это помещается

  readyEvent = null;
  isLoaded = false;

  #app = null;
  #component = null;
  #store = null;

  constructor(params = {}) {
    this.elLayout = q('.b-layout__global', 'one');
    this.elNotifyScene = createNotifyScene('notify-scene', this.elLayout).element;

    this.readyEvent = new CustomEvent('notify-manager-ready');

    (async _ => {
      await this.#load();
      this.isLoaded = true;
      document.dispatchEvent(this.readyEvent);
    })();
  }

  async #load(params = {}) {
    const { default: component } = await import('@cmp/Notify/index.vue');
    const { default: mixinBase } = await import('@/mixins/base.js');
    const { getStore } = await import('@/store/Notify');
    const app = createApp(component, { root: this.elNotifyScene, ...params });
    app.mixin(mixinBase);
    const Store = getStore();
    app.use(Store);
    app.mount(this.elNotifyScene);

    this.#app = Scope(app);
    this.#store = Scope(Store);
    this.#component = Scope(component);
  }

  #push({ title = '', message = '', props = {} }) {
    const store = this.#store();
    store.commit('Notify/push', { title, message, props });
  }

  push({ title = '', message = '', props = {} }) {
    this.#push({ title, message, props });
  }

  static run() { (globalThis || window)[namespace].NotifyManager = new this({}); }
}

export default async function(props) { domReady(_ => ModalManager.run()); };