import dayjs from 'dayjs';
import { str, int, isObject } from '@lib/type';
import { sp } from '@lib/stringTmpl';
import { trim } from '@lib/string';
import { pipe } from '@lib/fn';
import { pathOr } from 'ramda';

function thisLine() {
  const { stack = '' } = new Error();
  const regex = /(.*):(\d+):(\d+)$/
  const stackline = (stack.split("\n")[4]||'');
  const inContextLine = trim(stackline)
  .replace(/^at .*?\(/, '')
  .replace(/\)/, '');
  const match = regex.exec(inContextLine);

  const getMatch = (arrMatch, number = -1) =>  pathOr('', [ number ], arrMatch);
  return {
    filepath: getMatch(match, 1),
    line:     getMatch(match, 2),
    column:   getMatch(match, 3),
    stack:    stack.replace(/Error\s*/, ''),
  };
}

const formatThisLine = ({filepath: _filepath = '', line, column, stack}) => {
  const filepath = [
    [/^at\s/, ''],
    [new RegExp(location.origin), ''],
    [/(http|https):\/\/localhost:\d{2,4}/, ''],
    [/\?.*$/, ''],
  ].reduce((s, [re, rp]) => s.replace(re, rp), _filepath || '');
  return {str: `${filepath}:${line}:${column}`, stack};
};

export class Log {
  #levels = [
    sp`0 n non none`,
    sp`1 v vrb verbose`,
    sp`2 w wrn warning`,
    sp`3 e err error`,
  ];

  #level = -1;

  constructor(params = {}) {
    const { level = -1} = params;
    this.#setlevel(level);
  }

  create() {
    const self = this;
    let tmp = '';
    let countRepeat = 0;
    let stampTmp = '';
    let counter = 0;
    const watcher = ({
      ms = 10,
      idleTimeMax = 1000,
      checker,
      resolve,
    }) => {
      let timer = null;
      let counterMax = int(idleTimeMax / ms);
      counter = 0;
      const process = _ => {
        counter++;
        const cond = checker();
        if(counter > counterMax && cond) {
          counter = 0;
          clearTimeout(timer);
          return resolve();
        }
        timer = setTimeout(process, ms);
      };
      process();
    };
    return function (level = null, ...args) {
      if(level === null) return null;
      const line = formatThisLine(thisLine());
      const currentLevel = self.#level;
      const levelIndex = self.#findLevel(level, 0);
      const levelShort = self.#findLevel(level, 1);
      const time = dayjs().format('MM/DD hh:mm:ss');
      const payload = [];
      let res = null;
      if(currentLevel === 0) return null;
      if(levelIndex < currentLevel) return null;

      const stamp = `[${time}|${levelShort}]`;
      const isEqualStamps = stamp === stampTmp;
      if(!isEqualStamps) pipe(
        _ => self.#provider('G')(),
        _ => self.#provider('g')(`${stamp} .............................\n`),
      )();
      watcher({
        ms: 10,
        checker: _ => time !== dayjs().format('MM/DD hh:mm:ss'),
        resolve: _ => self.#provider('G')(),
      });
      payload.push(...args, {'?':{line: line.str, stack: line.stack}});
      payload.push(isEqualStamps ? '\n' : '\n\n');
      const strPayload = str(payload);

      if(strPayload === tmp) countRepeat++;
      else countRepeat = 0;

      tmp = str(payload);
      stampTmp = stamp;
      res = payload;
      if(countRepeat > 0) res = [`[r: ${countRepeat}]\n`];
      self.#provider(levelShort)(...res);
    };
  }
  #findLevel(val, atIndex) {
    const norm = v => pipe(str, trim)(v);
    const compare  = (a, b) => norm(a) === norm(b);
    const level = this.#levels
    .reduce((res, level) => {
      if(res !== -1) return res;
      const findedSign = level.find(sign => compare(sign, val));
      if(findedSign) res = atIndex === 0 ? int(level.at(atIndex)) : level.at(atIndex);
      return res;
    }, -1);
    if(level < 0 || level > this.#levels.length) this.#level = 0;
    return level;
  }

  #setlevel(val) { this.#level = this.#findLevel(val, 0); }

  #provider(mode) {
    const c = console;
    const fns = {
      'n': _ => null,
      'v': c.log,
      'w': c.warn,
      'e': c.error,
      'g': c.groupCollapsed,
      'G': c.groupEnd,
    }
    return function (...params) { fns[mode](...params)};
  }
  get level() { return this.#findLevel(this.#level, -1); }
  get levelIndex() { return this.#findLevel(this.#level, 0); }
  get levelShort() { return this.#findLevel(this.#level, 1); }
}

export default Log;
