import { namespace } from '@/settings';
import {
  q,
  clss,
  domReady,
} from '@lib/dom';
import modalManager from './modal-manager';

let ModalManager = null;

class ProfileEditButton  {
  static slcRoot              = '#profile-edit-button';
  static slcClassRoot         = 'b-cabinet-main-page__out-icon';
  static slcClassRootNotReady = 'b-cabinet-main-page__edit-icon--not-ready';

  elRoot = null;

  attrTitle = {
    wait:    'Загрузка...',
    profile: 'Редактировать профиль',
  }

  constructor (params = {}) {
    this.elRoot = params?.element || null;
    if(!this.elRoot) return null;
    this.setTitle(this.attrTitle['wait']);
    const hasClass = clss({element: this.elRoot, has: ProfileEditButton.slcClassRootNotReady});
    if(hasClass) clss({element: this.elRoot, remove: ProfileEditButton.slcClassRootNotReady});
    this.elRoot.addEventListener('click', ev => this.handler(ev));
    this.setTitle(this.attrTitle['profile']);
    document.addEventListener('modal-manager-ready', ev => {
      ModalManager = window[namespace]?.ModalManager;
    });
  }

  async handler(ev) {
    const modal = await import('@cmp/Modals/ModalEditProfile.vue');
    await ModalManager.push({ modal });
    ModalManager.open('ModalEditProfile');
  }

  setTitle (msg = '') {
    let elTitle = q('title', 'one', this.elRoot);
    if(!elTitle) {
      elTitle = document.createElementNS('http://www.w3.org/2000/svg', 'title');
      this.elRoot.insertAdjacentElement('afterbegin', elTitle);
    };
    elTitle.textContent = msg;
  }

  static run() {
    const element = q(ProfileEditButton.slcRoot, 'one');
    this.element = new this({ element });
  }

}

export default async function(props) { domReady(_ => ProfileEditButton.run()); };
