/** @type async function
*   @description: отложенный вызов функции
*   @param object: {callback: function, ms: int}
*   @returns: Promise result, result callback function
* */
export const delay = ({ callback: c = _ => null, ms }) => new Promise(r => {
  if (ms === 0) {
    r(c());
  } else {
    let t = setTimeout(_ => (clearTimeout(t), r(c())), ms);
  }
});

/** @type async function
*   @description: Последовательно выполнит функции в массиве с ожиданием завершения каждой
*   @param arr: collection[{ms: int, callback: function}]
*   @returns: undefined
* */
export const queue = async arr => {
  for (let { callback, ms = 0 } of arr) await delay({ callback, ms });
};

export const queueAlt = async (...obs) => {
  for (let { callback, ms = 0 } of obs) await delay({ callback, ms });
};


export const wait = ({ ms = 1000, before, after }) => {
  let timer = null;
  return new Promise(resolve => {
    before && before();
    timer = setTimeout(_ => {
      after && after();
      clearTimeout(timer);
      resolve();
    }, ms);
  });
};
export const waitConditionAlt2 = (params = {}) => new Promise((resolve, reject) => {
  const {
    onCheck = _ => true,
    onWait = _ => _,
    onFail = _ => _,
    onComplit = _ => _,
    maxCount = 10,
    waitStep = 100
  } = params;
  let count = 0;
  let timer = null;
  const process = _ => {
    count++;
    const status = onCheck && onCheck();
    onWait && onWait();
    if (count >= maxCount) {
      clearTimeout(timer);
      return reject(onFail && onFail());
    }
    if (status) {
      clearTimeout(timer);
      return resolve(onComplit());
    }
    timer = setTimeout(_ => process(params), waitStep);
  };
  process(params);
});

export const waitConditionAlt = async ({
  onCheck = _ => true,
  onWait = _ => _,
  onFail = _ => _,
  onComplit = _ => _,
  maxCount = 10,
  waitStep = 100
}) => {
  let count = 0;
  let timer = null;
  const process = _ => {
    count++;
    const status = onCheck && onCheck();
    onWait && onWait();
    if (status) {
      clearTimeout(timer);
      return onComplit();
    }
    if (count >= maxCount) {
      clearTimeout(timer);
      return onFail && onFail();
    }
    timer = setTimeout(_ => process({
      onCheck,
      onWait,
      onFail,
      onComplit,
      maxCount,
      waitStep,
    }), waitStep);
  };
  await process({
    onCheck,
    onWait,
    onFail,
    onComplit,
    maxCount,
    waitStep,
  });
};

export const waitCondition = ({
  ms = 1000,
  condition = _ => true,
  maxCount = 1
}) => {
  let [timer, count] = [null, 0];
  return new Promise(resolve => {
    const process = _ => {
      if (condition() || ++count > maxCount) return (clearTimeout(timer), resolve());
      timer = setTimeout(process, ms);
    }
    process();
  });
};

export const idleCalback = (maxTime, callback) => {
  // Dirty shit!
  let noActiveDelay = maxTime;
  let nowToActive = 0;

  function update(callback) {
    if (nowToActive >= noActiveDelay) {
      clearTimeout(t1);
      clearTimeout(t2);
      callback();
    }
  }
  let t1 = setInterval(() => { nowToActive++; }, 1000);
  let t2 = setInterval(() => { update(callback); }, 1000);
  document.addEventListener('mousemove', () => { nowToActive = 0; }, false);
};

const defaultModule = {
  delay,
  queue,
  queueAlt,
  wait,
  waitCondition,
  waitConditionAlt,
  waitConditionAlt2,
  idleCalback,
};

export default defaultModule;