import { pathOr, path } from 'ramda';
import { namespace, getDevStatus } from '@/settings';
import { exec } from '@api';
import {
  q,
  clss,
  domReady,
  element,
  insert,
  getAttr,
} from '@lib/dom';

import { sp } from '@lib/stringTmpl';

import { wait, waitConditionAlt } from '@lib/async';

let ModalManager  = null;

class CabinetButton  {
  static slcRoot           = '#cabinet-button';
  static slcBtnCabinetAuth = '.b-cabinet-main-page__auth-button';

  cabinetPage       = '/cabinet/';
  elRoot            = null;
  elBtnCabinetAuth  = null;
  isCallImmediately = false;
  isLogin           = false;
  keyAttribute      = 'data-call-immediately';

  isReadyManager    = false;
  isReadyInfo       = false;


  attrTitle = {
    wait:          'Загрузка...',
    isAuthorized:  'Личный кабинет',
    notAuthorized: 'Авторизация'
  };

  constructor (params = {}) {
    this.elRoot = pathOr(null, ['element'], params);
    if(!this.elRoot) return null;

    this.setTitle(path(sp `attrTitle wait`, this));

    const hasClass = clss({element: this.elRoot, has: 'b-header__menu-icon--not-ready'});
    if(!hasClass)    clss({element: this.elRoot, add: 'b-header__menu-icon--not-ready'});

    this.initElements();
    this.initHandlers();

    (async _ => {
      const { isLogin = false, urlCabinet = null, isCallImmediately = null } = await this.getInfo();
      const hasClass = clss({element: this.elRoot, has:    'b-header__menu-icon--not-ready'});
      if(hasClass)     clss({element: this.elRoot, remove: 'b-header__menu-icon--not-ready'});

      this.isLogin           = isLogin;
      this.isCallImmediately = isCallImmediately || this.isCallImmediately;
      this.cabinetPage       = urlCabinet        || this.cabinetPage;

      if(isLogin) this.setTitle(this.attrTitle['isAuthorized']);
      else        this.setTitle(this.attrTitle['notAuthorized']);

      if(this.isCallImmediately) {
        const trigger = _ => wait({ ms: 10, after: _ => { this.elRoot.dispatchEvent(new Event('click')); } });
        if(ModalManager?.isLoaded) trigger();
        else await this.waitingForReady({ trigger });
      }

      this.handlerInfo();
    })();
  }

  initElements() {
    this.elBtnCabinetAuth = q(CabinetButton.slcBtnCabinetAuth);
    if(this.elBtnCabinetAuth) {
      const modClass = 'b-cabinet-main-page__auth-button--not-ready';
      const hasClass = clss({element: this.elBtnCabinetAuth, has:    modClass});
      if(hasClass)     clss({element: this.elBtnCabinetAuth, remove: modClass});
    }
  }

  initHandlers() {
    this.elRoot.addEventListener('click', ev => this.handler(ev));
    if(this.elBtnCabinetAuth) this.elBtnCabinetAuth.addEventListener('click', ev => this.handler(ev));
    document.addEventListener('modal-manager-ready', ev => this.handlerReady(ev));
  }

  async handler(ev) {
    const { cabinetPage } = this;
    const root = this.elRoot.parentNode;

    const open = (url, target = '_self', root = document.body) => {
      const anchor = element('a', {target, href: url});
      insert(anchor, root);
      anchor.click();
    }

    let status = null

    await wait({after: _ => status = getDevStatus(), ms: 10});

    const redirect = async (url, status) => {
      let delay = async _ => new Promise(function(resolve) {resolve()});

      if ( status ) window.location.pathname = url;
      else await delay().then(_ => open(url, '_self', root));
    };


    const action = async _ => {
      const trigger = _ => ModalManager.open('ModalLogin');
      if(!this.isReady) await this.waitingForReady({trigger});
      else trigger();
    }

    if(this.isLogin) redirect(this.cabinetPage);
    else action();
  }

  handlerInfo() {
    this.isReadyInfo = true;
  }
  handlerReady(ev) {
    ModalManager = window[namespace].ModalManager;
    this.isReadyManager = true;
  }

  async waitingForReady({ trigger }) {
    return await waitConditionAlt({
      onCheck:   _ => this.isReady,
      onComplit: _ => trigger(),
      maxCount:  5000 / 100,
    })
  }

  setTitle (msg = '') {
    let elTitle = q('title', 'one', this.elRoot);
    if(!elTitle) {
      elTitle = document.createElementNS('http://www.w3.org/2000/svg', 'title');
      this.elRoot.insertAdjacentElement('afterbegin', elTitle);
    };
    elTitle.textContent = msg;
  }

  async getInfo() {
    const { isLogin = false, urlCabinet = null } = await exec('info');
    const isCallImmediately = getAttr(this.elRoot, this.keyAttribute);
    return { isLogin, urlCabinet, isCallImmediately };
  }

  get isReady () { return !!this.isReadyInfo && !!this.isReadyManager; }

  static run() {
    const element = q(CabinetButton.slcRoot, 'one');
    this.element = new this({ element });
  }


}

export default async function(props) { domReady(_ => CabinetButton.run()); };
