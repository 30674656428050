// dependencies
import { createApp } from 'vue';
import { hasIn, lensProp, set, view, path } from 'ramda';

import { namespace } from '@/settings';
import { trace } from '@/mixins/base/log';

// library
import {
  clss,
  domReady,
  eject,
  element,
  insert,
  q,
} from '@lib/dom';
import { isString, isObject, isArray } from '@lib/type';
import { sp } from '@lib/stringTmpl';


export const createModalTarget = (_id, _parent = null, _bemBlock = 'b-modal') => {
  trace('createModalTarget()', { _id, _parent, _bemBlock });
  const id = _id || 'modal-scene-target';
  const bemBlock = _bemBlock || 'b-modal';
  const parent = _parent || document.body;
  let elModalSceneTarget = q(`#${id}`, 'one');
  if (!elModalSceneTarget) {
    trace('createModalTarget() modal target is not exist. Will be created in next steps');
    elModalSceneTarget = element('div', { id, class: bemBlock });
    insert(elModalSceneTarget, parent);
  }
  return { element: elModalSceneTarget, id }
};

export const createModalScene = (_id, _parent = null, _bemBlock = 'b-modal') => {
  trace('createModalScene()', { _id, _parent, _bemBlock });
  const id = _id || 'modal-scene-target';
  const bemBlock = _bemBlock || 'b-modal';
  const parent = _parent || document.body;
  let elModalScene = q(`#${id}`, 'one');
  if (!elModalScene) {
    trace('createModalScene() modal scene is not exist. Will be created in next steps');
    elModalScene = element('div', { id, class: bemBlock });
    insert(elModalScene, parent);
  }
  return { element: elModalScene, id }
};

const { keys, entries } = Object;

export const scrollFix = ctx => {
  if (!ctx.elBody) ctx.elBody = document.body;
  if (!ctx.elHtml) ctx.elHtml = document.body.parentNode;
  const hasClassBody = clss({ element: ctx.elBody, has: '_fixed' });
  if (!hasClassBody) clss({ element: ctx.elBody, add: '_fixed' });
  const hasClassHtml = clss({ element: ctx.elHtml, has: '_fixed' });
  if (!hasClassHtml) clss({ element: ctx.elHtml, add: '_fixed' });
};
export const scrollUnfix = ctx => {
  if (!ctx.elBody) ctx.elBody = document.body;
  if (!ctx.elHtml) ctx.elHtml = document.body.parentNode;
  const hasClassBody = clss({ element: ctx.elBody, has: '_fixed' });
  if (hasClassBody) clss({ element: ctx.elBody, remove: '_fixed' });
  const hasClassHtml = clss({ element: ctx.elHtml, has: '_fixed' });
  if (hasClassHtml) clss({ element: ctx.elHtml, remove: '_fixed' });
};


class Dep {
  componentName = null;
  dependence = null;
  name = 'unknown';
  props = {};
  type = 'plugin';
  executor = _ => _;

  constructor(name, dependence, params = {}) {
    const {
      type = 'plugin',
      componentName = null,
      executor = null,
    } = params;
    this.name = name;
    this.dependence = dependence;
    this.type = type;
    this.componentName = componentName;
    if (executor) this.setExecutor(executor);
  }
  setProps(params) { this.props = { ...this.props, ...params }; }
  setExecutor(executor) { this.executor = executor.bind(this); }
  execute(app, props) { this.executor(app, props); }
};

class Store {
  #store = {};
  constructor(params = {}) { }

  set(keyStoreModule, keyProp, value) {
    if (!keyStoreModule) throw new Error('keyStoreModule is empty!');
    if (!keyStoreModule) throw new Error('keyProp is empty!');
    const moduleLense = lensProp(keyStoreModule);
    const propLense = lensProp(keyProp);
    if (!hasIn(keyStoreModule, this.#store)) this.#store = set(moduleLense, {}, this.#store);
    let storeModule = view(moduleLense, this.#store);
    storeModule = set(propLense, value, storeModule);
    this.#store = set(moduleLense, storeModule, this.#store);
  }
  get(keyStoreModule, keyProp) {
    if (!keyStoreModule) throw new Error('keyStoreModule is empty!');
    if (!keyStoreModule) throw new Error('keyProp is empty!');
    const moduleLense = lensProp(keyStoreModule);
    const propLense = lensProp(keyProp);
    let storeModule = view(moduleLense, this.#store);
    return view(propLense, storeModule);
  }
};

class ModalManager {

  elBody = document.body;
  elHtml = document.body.parentElement;
  elModalScene = null; // сцена для монтирования компонентов
  elModalTargetScene = null; // цель для телепорта
  elLayout = null; // слой куда это помещается
  lastOpenedModal = null; // последнее открытое окно
  modals = {};
  availableModals = [];

  isLoadedCommonModals = false;
  isLoadedCommonDependencies = false;

  #depMixins = [];
  #depComponents = [];
  #depPlugins = [];

  #store = null;

  readyEvent = null;

  constructor(params = {}) {
    this.elLayout = q('.b-layout__global', 'one');
    this.elModalTargetScene = createModalTarget('modal-scene-target', this.elLayout).element;
    this.elModalScene = createModalScene('modal-scene', this.elLayout).element;

    this.#store = new Store();

    this.readyEvent = new CustomEvent('modal-manager-ready');
    this.availableModals = AppModals || [];

    (async _ => {
      const arr = [this.#loadCommonDependencies.bind(this), this.load.bind(this),];
      for (const item of arr) await item();
      document.dispatchEvent(this.readyEvent);
    })();
  }

  async loadAvailableModal({ name }) {
    trace('ModalManager loadAvailableModal', { name });
    if (this.availableModals.includes(name) === false) throw new Error(`Modal ${name} is not available`);
    const modal = await import(`../components/Modals/${name}.vue`);
    this.push({ modal });
  }

  load() {
    return new Promise(resolve => {
      const modals = [
        import('@cmp/Modals/Modal2FA.vue'),
        import('@cmp/Modals/ModalDownload.vue'),
        import('@cmp/Modals/ModalError.vue'),
        import('@cmp/Modals/ModalLogin.vue'),
        import('@cmp/Modals/ModalRemember.vue'),
        import('@cmp/Modals/ModalSignUp.vue'),
        import('@cmp/Modals/ModalSuccess.vue'),
      ];
      const params = {
        ModalSignUp: { isStorageEnabled: true },
      };

      Promise.all(modals)
        .then(res => {
          res.forEach(modal => {
            const { default: component = null } = modal;
            const { name } = component;
            this.modals[name] = {
              component, params: { [name]: params[name] }
            };
          });
          this.isLoadedCommonModals = true;
          resolve();
        });
      trace('ModalManager / load was ended. Loaded modals: ' + modals.length);
    });
  }
  push({ modal, dependencies = {} }) {
    const { default: component = null } = modal;
    const { name } = component;
    const { mixins = [], components = [], plugins = [], } = dependencies;
    this.modals[name] = { component };
    mixins.forEach(({ name, mixin }) => {
      this.#depMixins.push(new Dep(name, mixin, {
        type: 'mixin',
        executor(app) { app.mixin(this.dependence); }
      }));
    });
    components.forEach(({ name, componentName, component }) => {
      this.#depComponents.push(new Dep(name, component, {
        type: 'component',
        componentName,
        executor(app) { app.component(this.componentName, this.dependence); }
      }));
    });
    plugins.forEach(({ name, plugin }) => {
      this.#depPlugins.push(new Dep(name, plugin, {
        type: 'plugin',
        executor(app) { app.use(this.dependence, this.props); }
      }));
    });
    trace('ModalManager / pushed Modal: ', name);
  }

  #checkExistModal(name) {
    const modals = keys(this.modals);
    return modals.includes(name);
  }

  #unmount(name, params = {}) {
    trace('ModalManager #unmount', { name, params });
    if (this.#checkExistModal(name) === false) return false;
    const modal = this.modals[name];
    const { app } = modal;
    if (!app) return false;
    app.unmount();
    return true;
  }

  #cleanDOM(name) {
    const modal = this.modals[name];
    const { root } = modal;
    eject(root);
  }

  #attachDependencies(app, props = {}) {
    const attach = (arr, app, props = {}) => {
      arr.forEach(item => {
        if (keys(props).includes(item.name)) item.setProps(props[item.name]);
        item.execute(app, props);
      });
      return nArr => attach(nArr, app, props);
    };
    attach(this.#depMixins, app, props)(this.#depComponents)(this.#depPlugins);
  }

  #loadCommonDependencies() {
    return new Promise(resolve => {
      const dependencies = [
        // mixins
        import('@/mixins/base.js'),

        //components
        import('vue-imask'),
        import('vue-select'),

        // plugins
        import('@plugins/vue3-form-generator/src/'),
        import('vue-universal-modal'),
      ];

      Promise.all(dependencies)
        .then(([
          // mixins
          mixinBase,

          //components
          Mask,
          vSelect,

          // plugins
          FormGenerator,
          VueUniversalModal,
        ]) => {
          // mixins

          this.#depMixins.push(new Dep('mixinBase', mixinBase.default, {
            type: 'mixin',
            executor(app) { app.mixin(this.dependence); }
          }));
          // components
          this.#depComponents.push(new Dep('Mask', Mask.IMaskComponent, {
            type: 'component',
            componentName: 'imask-input',
            executor(app) { app.component(this.componentName, this.dependence); }
          }));
          this.#depComponents.push(new Dep('vSelect', vSelect.default, {
            type: 'component',
            componentName: 'v-select',
            executor(app) { app.component(this.componentName, this.dependence); }
          }));

          // plugins
          this.#depPlugins.push(new Dep('FormGenerator', FormGenerator.default, {
            executor(app, opts) {
              const { modalParams = {} } = opts;
              const appName = path(sp`_component name`, app);
              app.use(this.dependence, { ...this.props, ...modalParams[appName] });
            }
          }));
          this.#depPlugins.push(new Dep('VueUniversalModal', VueUniversalModal.default, {
            executor(app) { app.use(this.dependence, this.props); }
          }));

          trace('ModalManager / Loaded common dependencies...');
          this.isLoadedCommonDependencies = true;
          resolve();
        });
    });
  }

  async #mount(name, params = {}) {
    trace('ModalManager #mount', { name, params });
    if (this.#checkExistModal(name) === false) return false;

    const modal = this.modals[name];
    const { app: _app = null, component, params: modalParams = {} } = modal;

    let root = q(`${name}-target`, 'id');
    if (!root) {
      root = element('div', { id: `${name}-target`, });
      insert(root, this.elModalScene);
    }
    if (_app !== null) this.#unmount(name);

    const app = createApp(component, { root, ...params });

    const props = {
      VueUniversalModal: {
        teleportTarget: `#${this.elModalTargetScene.id}`,
      },
      modalParams,
    };
    this.#attachDependencies(app, props);
    app.mount('#' + root.id);

    this.modals[name].root = root;
    this.modals[name].app = app;
    this.modals[name].params = params;
    return true;
  }

  async open(name, params = {}) {
    let status = await this.#mount(name, params);
    if (!status) throw new Error(`Modal ${name} is not exist or creating failed`);
    this.#scrollFix();
  }

  async close(name, params = {}) {
    const {
      before = null,
      nextBefore = null,
      after = null,
      nextAfter = null,
      storeParams = null,
      next
    } = params;
    if (this.#checkExistModal(name) === false) return false;
    const flush = _ => {
      this.#scrollUnfix();
      this.#unmount(name)
      this.#cleanDOM(name);
    };
    if (storeParams) {
      const { module, payload } = storeParams;
      entries(payload)
        .forEach(([key, value]) => this.#store.set(module, key, value));
    }
    if (before) before(this);

    if (nextBefore) await nextBefore(this, flush);
    else flush();

    if (after) after(this);

    if (nextAfter) await nextAfter(this);
    if (next) next(this);
  }

  get isLoaded() {
    return this.isLoadedCommonModals && this.isLoadedCommonDependencies;
  }

  #scrollFix() { scrollFix(this); }
  #scrollUnfix() { scrollUnfix(this); }

  getStore(keyModule, __q = []) {
    if (isString(__q)) {
      return this.#store.get(keyModule, __q);
    } else if (isArray) {
      return __q.reduce((sum, key) => {
        sum[key] = this.#store.get(keyModule, key);
        return sum;
      }, {});
    }
    return null;
  }
  setStore(keyModule, key, val) {
    if (isObject(key)) {
      Object
        .entries(key)
        .forEach(([k, v]) => this.#store.set(keyModule, k, v));
    } else {
      this.#store.set(keyModule, key, val);
    }
    return this;
  }

  static run() { window[namespace].ModalManager = new this({}); }
}

export default async function(props) { domReady(_ => ModalManager.run()); };