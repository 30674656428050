import { createApp } from 'vue';
import { q } from '@lib/dom';
import { createModalTarget } from '@/modules/modal-manager';
import { trace }     from '@/mixins/base/log';

export default async function(params) {
  const root = q('#conferences-app', 'one');

  trace('conferences-app entrypoint loaded');
  if(root) {
    trace('conferences-app entrypoint / condition is satisfied');
    const mixinBase         = await import('@/mixins/base.js');
    const mixinAuth         = await import('@/mixins/auth.js');
    const FormGenerator     = await import ('@plugins/vue3-form-generator/src/');
    const VueUniversalModal = await import('vue-universal-modal');
    const vSelect           = await import('vue-select');
    const Mask              = await import('vue-imask');

    const router = await import('@/routers/ConferencesApp');
    const { Store:  storePagePropsModule = {} } = await import('@/store/PageProps/Module');

    let elLayout = q('.b-layout__global', 'one');
    const { id: modalTargetId } = createModalTarget('modal-scene-target', elLayout);

    const App = await import('@cmp/ConferencesApp/index.vue');
    const app = createApp(App.default, { root });

    app.mixin(mixinBase.default);
    app.mixin(mixinAuth.default);
    app.use(router.default);

    app.use(VueUniversalModal.default, { teleportTarget: `#${modalTargetId}`, });
    const modules = [{
      namespace: storePagePropsModule.namespace, module: storePagePropsModule,
    }]
    app.use(FormGenerator.default, { modules});
    app.component('v-select', vSelect.default);
    app.component('imask-input', Mask.IMaskComponent);

    app.mount('#' + root.id);
  }

}
