import CabinetButton from '@/modules/cabinet-button.js';
import ModalManager from '@/modules/modal-manager.js';
import NotifyManager from '@/modules/notify-manager.js';
import ProfileEditButton from '@/modules/profile-edit-button.js';

export default {
  CabinetButton,
  ModalManager,
  NotifyManager,
  ProfileEditButton,
}