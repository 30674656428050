// TODO: make tests
export const getType = val => typeof val;
export const type = getType;

export const num = val => Number(val);
export const int = (arg, sys = 10) => parseInt(arg, sys);
export const flt = (val, limit = null) => {
  let res = parseFloat(val);
  if(limit) res = res.toFixed(limit);
  return res;
}
export const str = arg => String(arg);
export const bool = arg => Boolean(arg);

export const up = _ => str(_).toLocaleUpperCase();
export const lo = _ => str(_).toLocaleLowerCase();

export const isExist = (arg, boolCheck = false, stringCheck = false) => {
  // TODO: нужно инвертировать это!
  let conditions = [
    arg === undefined,
    arg === null,
    Number.isNaN(arg)
  ];
  if (boolCheck === true) conditions.push(arg === false);
  if (stringCheck === true) conditions.push(arg === '');
  return !conditions.some(item => item === true);
};

export const isU = _ => _ === undefined;

export const isArray = array => {
  if (!isExist(array, true)) return false;
  if (type(array) !== 'object') return false;
  if (Object.getPrototypeOf(array).constructor.name !== 'Array') return false;
  return true;
};

export const isObject = val => {
  if (!isExist(val)) return false;
  if (type(val) !== 'object') return false;
  if (isArray(val)) return false;
  return true;
};

// BUG: maybe bug:
export const isEmpty = array => {
  if (!isArray(array)) return true;
  return array.length === 0;
};

export const isString = val => {
  if (!isExist(val, true)) return false;
  if (type(val) !== 'string') return false;
  if (Object.getPrototypeOf(val).constructor.name !== 'String') return false;
  return true;
};

export const isNumber = val => {
  if (!isExist(val, true)) return false;
  if (type(val) !== 'number') return false;
  if (Object.getPrototypeOf(val).constructor.name !== 'Number') return false;
  return true;
};

export const isInt = val => isNumber(val) && Number.isInteger(val);

export const isInteger = val => isInt(val);

export const isFloat = val => isNumber(val) && !isInt(val);

export const isBool = val => {
  if (!isExist(val)) return false;
  if (type(val) === 'boolean') return true;
  return false;
};

export const isFunction = val => {
  if (!isExist(val)) return false;
  if (type(val) === 'function') return true;
  return false;
};

export const isPromise = v => (v instanceof Promise && !!v.then) || (v?.constructor?.name === 'AsyncFunction');

export const isAsync = fn => fn[Symbol.toStringTag] === 'AsyncFunction';

export const isBoolean = isBool;
const defaultModule = {
  bool,
  flt,
  getType,
  int,
  isArray,
  isAsync,
  isBool,
  isBoolean,
  isEmpty,
  isExist,
  isFloat,
  isFunction,
  isInt,
  isInteger,
  isNumber,
  isObject,
  isPromise,
  isString,
  isU,
  lo,
  num,
  str,
  type,
  up,
};

export default defaultModule;
