// dependencies: ramda
import { omit, pathOr } from 'ramda';

//library
import { sp } from '@lib/stringTmpl';

const PROD = 'production';
export class Version {
  #properties = [];
  mode = PROD;
  constructor(params = {}) {
    const { mode = PROD } = params;
    this.mode = mode;
  }

  push(o, forPublic = false) {
    const { entries } = Object;
    const res = omit(sp`key`, o);
    const [[key, value]] = entries(res);
    const reKey = pathOr(null, sp`key`, o);
    this.#properties.push({ key: reKey || key, value, public: forPublic });
    return this;
  }

  show() { console.table(this.properties); }

  get isProductionMode() { return this.mode === PROD; }
  get properties() {
    const { isProductionMode } = this;
    return this.#properties
      .filter(({ public: p = false }) => ((isProductionMode && p) || (!isProductionMode)))
      .sort(({ key: a }, { key: b }) => a < b ? -1 : 1)
      .reduce((s, { key, value }) => (s[key] = value, s), {});

  }
}

export default Version;