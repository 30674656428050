// helpers
import { Log } from '@/helpers/Log'

// settings
import { logLevel } from '@/settings';
import { getTraceStatus } from '@/settings';

// logger
export const log = new Log({ level: logLevel }).create();
export const trace = (...args) => getTraceStatus() ? log('v', ...args) : void null;
export const warn = (...args) => log('w', ...args);
export const fatal = (...args) => log('e', ...args);

export default {
  data() {
    return {
      // log ========================
      logLevel,
      $log: log,
      trace,
      warn,
      fatal,
    }
  },
}