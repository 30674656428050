import entryPoints from '@/entries';
import modules from '@/modules';

import { log } from '@/mixins/base/log';
import { methods, namespace, isDev, showVersion } from '@/settings.js';

//import '@less/index.less';

const { entries } = Object;

const params = {
  common: _ => {
    window[namespace] = {
      ...(window[namespace] || {}),
      ...methods,
    }
  }
};

if (isDev()) log(showVersion());

const run = async () => {
  params.common();
  for (let [key, point] of entries(entryPoints)) await point({ ...params[key] });
  for (let [key, module] of entries(modules)) await module({ ...params[key] });
};
run();