import { isPromise } from "./type.js";

export const pipe = (...fns) => x => fns.reduce((v, f) => f(v), x);


export const pipeAsync = (...fns) => x => new Promise(async resolve => {
  let res = x;
  for (let f of fns) res = await f(res);
  resolve(res);
});

export const pipeAsyncAlt = async function(/*functions*/) {
  const fns = Array.prototype.slice.call(arguments, 0);
  let res = null;
  for (let index = 0; index < fns.length; index++) {
    let fn = fns[index];
    if (isPromise(fn)) res = await fn(res);
    else if (typeof fn === 'function') res = fn(res);
  }
  return res;
}
export const altQueue = function(...fns) {
  return function(value) {
    let res = false;
    for (let f of fns) {
      res = f(value);
      if (res) break;
    }
    return res;
  };
};

export const alt = function(f1, f2) {
  return function(value) {
    return f1(value) || f2(value);
  };
};

export const queue = function(/*functions*/) {
  const fns = Array.prototype.slice.call(arguments, 0);
  for (let index = 0; index < fns.length; index++) fns[index] && fns[index]();
}

export const queueAsync = async function(/*functions*/) {
  const fns = Array.prototype.slice.call(arguments, 0);
  for (let index = 0; index < fns.length; index++) {
    let fn = fns[index];
    if (isPromise(fn)) await fn();
    else fn();
  }
}
export class Debouncer {
  /***
  * ** on init:
  * let debouncer = new Debouncer({ msStep: 100, countMax: 15 });
  * ...
  * ** on event:
  * debouncer
  * .interrupt()
  * .resolve(_ => { emit('update'); });
  * ...
  * ** clean:
  * debouncer.destroy();
  ***/
  msStep = 100;
  countMax = 10;
  countCurrent = 0;
  resolver = _ => _;
  timer = null;

  constructor(params = {}) {
    const {
      msStep = 100,
      countMax = 10,
    } = params;
    this.msStep = msStep;
    this.countMax = countMax;
  }

  destroy(fn = null) {
    clearTimeout(this.timer);
    this.countCurrent = 0;
    fn && fn();
    return this;
  }

  interrupt() {
    this.destroy();
    const self = this;
    const process = _ => {
      self.countCurrent++;
      if (self.countCurrent >= self.countMax) self.destroy(self.resolver);
      else self.timer = setTimeout(process, self.msStep);
    }
    process();
    return this;
  }
  resolve(fn) {
    this.resolver = fn;
    return this;
  }

}

export class Counter {
  /*** DESCRIPTION: Создание счетчика
  * В параметрах можно указать:
  *   step -- шаг. По умолчанию 1;
  *   count -- начальное значение счетчика. По умолчанию -1.
  ***/
  // const counter = new Counter();
  // console.log(counter.counter);   // 0
  // console.log(counter.counter);   // 1
  // console.log(counter.counter);   // 2
  // console.log(counter);           // Counter {...}
  // console.log(counter + '');      // '4'
  // console.log(counter + 55);      // 60
  // console.log(parseInt(counter)); // 6
  #step = 1;
  #count = -1;
  #genCounter = null;
  constructor(params = {}) {
    const { step = 1, count = -1 } = params;
    this.#step = step;
    this.#count = count;
    const [$step, $count] = [this.#step, this.#count];
    function* countMaker() {
      let [step, count] = [$step, $count];
      while (true) yield ((count += step), count);
    }
    this.#genCounter = countMaker();
  }

  #gen() {
    this.#count = this.#genCounter.next().value;
    return this.#count;
  }
  toString() { return this.counter; }

  get counter() { return this.#gen(); }
};

const defaultModule = {
  alt,
  altQueue,
  pipe,
  pipeAsync,
  pipeAsyncAlt,
  queue,
  queueAsync,
  Debouncer,
  Counter,
};

export default defaultModule;