import { str, isObject, isArray, isString, isBoolean } from './type.js';
import { Cache } from './vue.js';
export const up = _ => str(_).toLocaleUpperCase();
export const lo = _ => str(_).toLocaleLowerCase();
export const trim = _ => str(_).trim();

export const r = (string, ...values) => string[0].repeat(values[0]);

/** Deprecated **/
export const replaceTmpl = (_strVal, tmpl = null, params = {}) => {
  if (!tmpl) return _strVal;
  let inputString = str(_strVal || '');
  const { entries } = Object;
  const [tStartDefault, tEndDefault] = ['%', '%'];
  const { tStart = tStartDefault, tEnd = tEndDefault } = params;
  return entries(tmpl)
    .reduce((sum, [key, val]) => {
      const r = new RegExp(`${tStart}${key}${tEnd}`, 'gm');
      return sum.replace(r, val);
    }, inputString).replace(new RegExp(`${tStart}.*${tEnd}`, 'gim'), '');
};

export const capitalize = strVal => up(strVal.substring(1));
export const firstLetterUp = s => str(s)
  .split('')
  .map((l, i) => i === 0 ? up(l) : l)
  .join('');

export const toCamelCase = s => str(s)
  .split('-')
  .map((w, i) => i !== 0 ? firstLetterUp(w) : w)
  .join('');

// bool indexOf
export const bio = (where, query) => lo(where).indexOf(lo(query)) !== -1;

export const stringGuard = (value, array, def = false) => (array.includes(value) ? value : !def ? array[0] : def);

export const rusEnding = (n, n1, n2, n5) => {
  // rusEnding(40, "комментарий", "комментария", "комментариев");
  if (n >= 11 && n <= 19) return n5;
  n = n % 10;
  if (n == 1) return n1;
  if (n >= 2 && n <= 4) return n2;
  return n5;
};


/** DESCRIPTION:
  * R - Replacer;
  * const r = new R({tStart: '{{', tEnd: '}}'});
  * const lib = {a: 'A!!!', b: 'B!!!', c: '{{a}} !== {{b}}'};
  * const strVal = 'test string {{a}}, {{b}}, {{c}}';
  * console.log(r.r(strVal, lib)); >> test string A!!!, B!!!, A!!! !== B!!!
  **/
export class R {
  EMPTY = '';
  tStart = '%';
  tEnd = '%';
  maxUseRecursiveInclude = 1;
  useCache = true
  cache = Cache;

  /** params = {tStart String, tEnd String, useCache Boolean} **/
  constructor(params = {}) {
    Object.assign(this, params);
    if (this.useCache) this.r = this.cache.__cache(this.__r, this.cache.hash);
    else this.r = this.__r;
  }

  /** val String
  * return String
  **/
  prepareTmpl(_val) {
    const val = str(_val);
    const symbols = [...new Set((this.tStart + this.tEnd).split(''))]
      .map(s => `\\${s}`)
      .join('|');
    const r = new RegExp(`(${symbols})`, 'gim')
    return val.replace(r, '\\$1');
  }

  /** id String, lib Object
  * return String
  **/
  getLibReplById(id, lib) {
    let repl = lib.hasOwnProperty(id) ? lib[id] : this.EMPTY;
    const resSelfIncl = this.hasSelfIncluded(repl, id);
    if (resSelfIncl && resSelfIncl !== 'notString') {
      const [_, tag] = resSelfIncl;
      const r = new RegExp(this.prepareTmpl(tag), 'gim');
      repl = repl.replace(r, this.EMPTY);
    }
    return repl;
  }

  /** template to id
   * strVal String
  * return String
  **/
  t2id(strVal) {
    const [ts, te] = [this.prepareTmpl(this.tStart), this.prepareTmpl(this.tEnd)];
    const [_, id] = strVal.match(new RegExp(`${ts}(.*?)${te}`));
    return id;
  }

  id2t(val) {
    const [ts, te] = [this.tStart, this.tEnd];
    return `${ts}${val}${te}`;
  }

  /** strVal String
  * return Boolean
  **/
  test(strVal) {
    const [ts, te] = [this.prepareTmpl(this.tStart), this.prepareTmpl(this.tEnd)];
    const r = new RegExp(`${ts}.*?${te}`, 'gim');
    return r.test(strVal);
  }


  /** return RegExp **/
  get rTag() {
    const { tStart: _ts = '', tEnd: _te = '' } = this;
    const [ts, te] = [this.prepareTmpl(_ts), this.prepareTmpl(_te)];
    return new RegExp(`${ts}.+?${te}`);
  }

  /** strVal String
  * return Array
  **/
  getTags(strVal) {
    return [...strVal.match(this.rTag) || []]
      .map(tag => [this.t2id(tag), tag])
  }

  /** id String
  * return String
  **/
  makeTag(id) { return `${this.tStart}${id}${this.tEnd}`; }


  /** strVal String, id: String
  * return undefined || Array [id String, tag String]
  **/
  hasSelfIncluded(strVal, id) {
    if (!isString(strVal)) return 'notString';
    return this
      .getTags(strVal)
      .find(([_id]) => _id === id);
  }

  /** inStr String, lib Object, params Object
  * return String
  **/
  __r(inStr, lib, params = {}) {
    if (inStr === undefined) return inStr;
    if (inStr === null) return inStr;
    if (inStr === '') return inStr;
    if (typeof inStr === 'boolean') return inStr;
    const test = strVal => this.test(strVal);
    const getRepl = id => this.getLibReplById(id, lib);

    const { usedLibKeys = {} } = params;
    const used = Object.assign({}, usedLibKeys);

    if (inStr.hasOwnProperty('notString') && inStr.notString === true) return inStr;
    let res = inStr;

    const tags = this.getTags(inStr);
    for (let index = 0; index < tags.length; index++) {
      const [id, tag] = tags[index];
      if (used.hasOwnProperty(id) === false) used[id] = { value: 1 };
      else used[id].value++;

      let repl = getRepl(id);

      if (isObject(repl) || isArray(repl) || isBoolean(repl)) {
        repl.notString = true;
        return repl;
      }

      if (used[id].value > this.maxUseRecursiveInclude) {
        const _tag = this.makeTag('.*?');
        const _r = new RegExp(this.prepareTmpl(_tag), 'gim');
        repl = repl.replace(_r, this.EMPTY);
      }

      const r = new RegExp(this.prepareTmpl(tag), 'gim');
      res = res.replace(r, repl);
    }

    const newParams = { ...params, usedLibKeys: used };
    if (test(res)) return this.r(res, lib, newParams);
    return res;
  };
};

const defaultModule = {
  R,
  bio,
  capitalize,
  firstLetterUp,
  lo,
  r,
  replaceTmpl,
  rusEnding,
  stringGuard,
  toCamelCase,
  trim,
  up,
};

export default defaultModule;